import React, { FC } from 'react';
import { Element } from 'react-scroll';
import { v5 as uuid } from 'uuid';

import TextBlock from 'components/TextBlock';

import { IArticleSectionProps } from './model';

const ArticleSection: FC<IArticleSectionProps> = ({ articleSectionTitle, articleSectionContent }) =>
  !articleSectionTitle ? (
    <TextBlock className="article-section-content with-gap" html={articleSectionContent} />
  ) : (
    <Element
      name={uuid(articleSectionTitle, '1ca671c3-34da-4212-8392-fc2ecdb33e39')}
      className="element"
    >
      <TextBlock className="article-section-title" html={articleSectionTitle} element="h2" />
      <TextBlock className="article-section-content" html={articleSectionContent} />
    </Element>
  );

export default ArticleSection;
