import React, { FC } from 'react';
import TagsList from 'gatsby-theme-husky/src/components/TagsList';
import Button from 'gatsby-theme-husky/src/common/Button';
import { ArticleSubMenuProps } from 'gatsby-theme-husky/src/components/ArticleSubMenu/model';
import ArticleSubMenuList from './ArticleSubMenuList';
import ArticleSubMenuHeader from './ArticleSubMenuHeader';
import ArticleSubMenuTopics from './ArticleSubMenuTopics';
import 'gatsby-theme-husky/src/components/ArticleSubMenu/ArticleSubMenu.scss';
import './ArticleSubMenuExtends.scss';

const ArticleSubMenu: FC<ArticleSubMenuProps> = ({
  content,
  bodySubNavigation: [
    {
      properties: {
        subnavigationMenuButtonULR: [{ url }],
        subnavigationMenuButton,
        subnavigationMenuTitle,
      },
    },
  ],
  tags,
  articleSubMenuTopics,
}) => {
  return (
    <div className="article-submenu">
      <Button
        className="article-submenu__button"
        ariaLabel={subnavigationMenuButton}
        btnColorVariant="outline-dark"
        link={url || '/'}
      >
        {subnavigationMenuButton}
      </Button>
      <ArticleSubMenuHeader {...{ subnavigationMenuTitle }} />
      <ArticleSubMenuList {...{ content }} />
      <ArticleSubMenuTopics {...{ articleSubMenuTopics }} />
      <TagsList dark tags={tags} />
    </div>
  );
};

export default ArticleSubMenu;
