import React, { FC, useMemo } from 'react';

import { DangerouslySetInnerHtml } from 'layout';

import { articleFormatDate } from 'utils/helpers';

import { IArticleDateProps } from './model';
import './ArticleDate.scss';

const ArticleDate: FC<IArticleDateProps> = ({
  createDateText,
  updateDateText,
  createDate,
  updateDate,
  lang,
}) => {
  const { articleUpdateDate, articleCreateDate } = useMemo(
    () => ({
      articleUpdateDate: updateDate ? articleFormatDate(updateDate, lang) : '',
      articleCreateDate: createDate ? articleFormatDate(createDate, lang) : '',
    }),
    [updateDate, createDate]
  );

  return (
    <div className="article-date">
      <div className="article-date__wrapper">
        <DangerouslySetInnerHtml className="article-date__text" html={createDateText} />
        <DangerouslySetInnerHtml className="article-date__date" html={articleCreateDate} />
      </div>
      <div className="article-date__wrapper">
        <DangerouslySetInnerHtml className="article-date__text" html={updateDateText} />
        <DangerouslySetInnerHtml className="article-date__date" html={articleUpdateDate} />
      </div>
    </div>
  );
};

export default ArticleDate;
