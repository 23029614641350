import React, { FC } from 'react';
import { stringUtils } from 'utils';
import { DangerouslySetInnerHtml } from 'layout';
import { ITextBlockProps } from './model';

const TextBlock: FC<ITextBlockProps> = ({ html, className, element = 'div' }) => {
  const indexedAndCapitalizedHtml = stringUtils.titleCapitalized(html);

  return (
    <DangerouslySetInnerHtml
      className={`text-block ${className}`}
      element={element}
      html={className === 'article-section-title' ? indexedAndCapitalizedHtml : html}
    />
  );
};

export default TextBlock;
