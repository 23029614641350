import React, { FC } from 'react';

import Video from 'components/Video';

import { IVideoArticleSectionProps } from './models';
import './VideoArticleSection.scss';
import './VideoArticleSectionExtends.scss';

const VideoArticleSection: FC<IVideoArticleSectionProps> = ({ videoLink, title, subTitle }) => {
  return (
    <div className="video-article-section__wrapper">
      {title ? <div className="video-article-section__title">{title}</div> : null}
      <Video videoURL={videoLink} />
      {subTitle ? <div className="video-article-section__sub-title">{subTitle}</div> : null}
    </div>
  );
};

export default VideoArticleSection;
