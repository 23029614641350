import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import VideoPlayer from './VideoPlayer';
import { VideoProps } from './models';
import getId from './helpers';
import './Video.scss';

const Video: FC<VideoProps> = ({ videoURL, className, videoClassName, ...restProps }) => {
  if (!videoURL) return null;

  const videoId = useMemo(() => getId(videoURL), [videoURL]);
  const isCMSVideo = useMemo(() => videoId.includes('http'), [videoId]);
  const isAutoPlay = restProps.autoplay;

  return (
    <div className={classNames('video-frame', className)}>
      {isCMSVideo ? (
        <VideoPlayer
          videoURL={videoId}
          className={classNames('video-frame__player', videoClassName)}
          {...restProps}
        />
      ) : (
        <div className="container-iframe">
          <iframe
            frameBorder={0}
            width="100%"
            src={`https://www.youtube-nocookie.com/embed/${videoId}${
              isAutoPlay ? '?autoplay=1' : ''
            }`}
            title="YouTube video player"
            allow={`accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; ${
              isAutoPlay ? 'autoplay' : ''
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default Video;

export const query = graphql`
  fragment FragmentVideo on TVideo {
    properties {
      videoLink
      videoCMS {
        fallbackUrl
        url
      }
      image {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      localImage {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageAlt
    }
  }
`;
