import React, { FC } from 'react';
import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import { ArticleBodyImageProps } from './models';

const ArticleBodyImage: FC<ArticleBodyImageProps> = ({
  articleBodyImage,
  articleBodyImageAltText,
  showArticleBodyImageMobileView,
}) => (
  <>
    {articleBodyImage?.gatsbyImage?.childImageSharp && (
      <GatsbyImage
        className={showArticleBodyImageMobileView === 1 ? 'show-mobile' : ''}
        isLazyLoading
        fluid={articleBodyImage?.gatsbyImage?.childImageSharp.fluid}
        alt={articleBodyImageAltText}
      />
    )}
    {articleBodyImage?.svg && <DangerouslySetInnerHtml html={articleBodyImage?.svg.content} />}
  </>
);

export default ArticleBodyImage;
