import React, { FC } from 'react';
import TextBlock from 'components/TextBlock';
import { IQuoteProps } from './models';
import './Quote.scss';

const Quote: FC<IQuoteProps> = ({ html }) => (
  <blockquote className="blockquote" data-testid="blockquote">
    <TextBlock className="blockquote-text" html={html} />
  </blockquote>
);

export default Quote;
