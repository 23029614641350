import React, { FC } from 'react';
import { Link } from 'react-scroll';
import { v5 as uuid } from 'uuid';

import ArticleSubMenuItemTitle from './ArticleSubMenuItemTitle';
import { ISubMenuItem } from './model';
import './ArticleSubMenuItem.scss';

const ArticleSubMenuItem: FC<ISubMenuItem> = ({ name, linkToSection = '' }) => {
  const settings = {
    to: uuid(linkToSection, '1ca671c3-34da-4212-8392-fc2ecdb33e39'),
    duration: 500,
    spy: true,
    smooth: true,
  };

  return name !== ' ' ? (
    <li className="article-submenu-item">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link {...settings} activeClass="active" className="article-submenu-item-link" href="#">
        <ArticleSubMenuItemTitle title={name} />
      </Link>
    </li>
  ) : null;
};

export default ArticleSubMenuItem;
