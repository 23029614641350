import React, { FC, useState, useCallback } from 'react';
import classNames from 'classnames';

import GatsbyImage from 'common/GatsbyImage';

import { VideoPlayerProps } from './models';
import './VideoPlayer.scss';

const VideoPlayer: FC<VideoPlayerProps> = ({
  videoURL,
  thumbnail,
  isThumbnailLazyLoading,
  className,
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const togglePlay = useCallback(() => setIsPlaying(!isPlaying), []);
  const hasFacade = thumbnail?.childImageSharp;

  return isPlaying || !hasFacade ? (
    <video controls autoPlay className={classNames('video-player__video', className)}>
      <source src={videoURL} type="video/mp4" />
      <track default kind="captions" />
    </video>
  ) : (
    <>
      {hasFacade ? (
        <GatsbyImage
          isLazyLoading={isThumbnailLazyLoading}
          alt="thumbnail"
          fluid={thumbnail ? thumbnail.childImageSharp.fluid : null}
          className={classNames('video-player__thumbnail', className)}
          data-testid="video-thumbnail"
        />
      ) : null}
      <button
        aria-label="play"
        className="video-player__play-button icon-social-youtube"
        onClick={togglePlay}
        type="button"
        data-testid="video-play-button"
      />
    </>
  );
};

export default VideoPlayer;
