import React, { FC } from 'react';

import BodyRenderer from 'common/BodyRenderer';
import TextBlock from 'components/TextBlock';
import Quote from 'components/Quote';
import ArticleBodyImage from 'components/ArticleBodyImage';
import ArticleSection from 'components/ArticleSection';
import VideoArticleSection from 'components/VideoArticleSection';

import { IArticleBodyProps } from './model';
import './ArticleBody.scss';
import './ArticleBodyExtends.scss';

const elements = {
  'Article introduction': ({ properties: { articleIntroductionText } }, keyId) => (
    <TextBlock key={keyId} className="article-introduction" html={articleIntroductionText} />
  ),
  'Article section': ({ properties: { articleSectionTitle, articleSectionContent } }, keyId) => (
    <ArticleSection
      key={keyId}
      articleSectionTitle={articleSectionTitle}
      articleSectionContent={articleSectionContent}
    />
  ),
  Quote: ({ properties: { quote } }) => <Quote html={quote} />,
  'Article Body Image': (
    { properties: { articleBodyImageAltText, showArticleBodyImageMobileView, articleBodyImage } },
    keyId
  ) => (
    <ArticleBodyImage
      key={keyId}
      articleBodyImage={articleBodyImage}
      articleBodyImageAltText={articleBodyImageAltText}
      showArticleBodyImageMobileView={showArticleBodyImageMobileView}
    />
  ),
  'Video Section': ({ properties: { videoLink, title, subTitle } }) => (
    <VideoArticleSection videoLink={videoLink} title={title} subTitle={subTitle} />
  ),
};

const ArticleBody: FC<IArticleBodyProps> = ({ content }) => {
  return (
    <div className="article-body">
      <BodyRenderer bodyData={content} bodyStructure={elements} />
    </div>
  );
};

export default ArticleBody;
